import { CommonModule, NgOptimizedImage } from '@angular/common';
import {NgModule} from '@angular/core';
import {FocusDirective} from 'src/app/platform/shared/directives/focus.directive';
import {HeaderComponent} from './components/header/header.component';


@NgModule({
  declarations: [HeaderComponent, FocusDirective],
  exports: [
    HeaderComponent,
    FocusDirective
  ],
    imports: [
        CommonModule,
        NgOptimizedImage
    ]
})
export class SharedModule { }
