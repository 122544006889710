import {AfterViewInit} from '@angular/core';
import {ElementRef} from '@angular/core';
import {Directive} from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit {

  constructor(private el: ElementRef<HTMLElement>) { }

  ngAfterViewInit(): void {
    this.el.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
  }
}
